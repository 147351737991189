
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "ForwarderJobTypeShippedViaSelect",
  props: {
    forwarder_job_shipped_vias_id: {
      type: [Number, Array],
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:forwarder_job_shipped_vias_id"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const typeshippedviaData = computed(
      () => store.getters.ForwarderJobTypeShippedVia
    );
    const code = ref("");
    const inputSalesJobTrafficType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isSalesJobTrafficTypeIdRequired = (value) => {
      if (props.required && !value) {
        return t("salejobtypeshippedviaid");
      }
      return true;
    };

    const {
      errorMessage,
      value: element_id,
      meta,
    } = useField("sale_job_shipped_vias_id", isSalesJobTrafficTypeIdRequired);

    const getSalesJobTrafficType = () => {
      if (!typeshippedviaData.value.length) {
        ApiService.get("/api/forwarder/job/shipped_via").then(({ data }) => {
          store.commit(
            "setForwarderJobTypeShippedVia",
            data.forwarder_job_shipped_vias
          );
          inputSalesJobTrafficType.value.list = data.forwarder_job_shipped_vias;
          inputSalesJobTrafficType.value.options =
            data.forwarder_job_shipped_vias;
        });
      } else {
        inputSalesJobTrafficType.value.list = typeshippedviaData.value;
        inputSalesJobTrafficType.value.options = typeshippedviaData.value;
      }
      if (props.multiselect) {
        inputSalesJobTrafficType.value.options.splice(0, 0, {
          id: 0,
          name: "Todos",
        });
      }
    };
    const selectSalesJobTrafficType = (query) => {
      if (query !== "") {
        inputSalesJobTrafficType.value.loading = true;
        setTimeout(() => {
          inputSalesJobTrafficType.value.loading = false;
          inputSalesJobTrafficType.value.options =
            inputSalesJobTrafficType.value.list.filter(
              (item: Record<string, any>) => {
                return (
                  item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
                );
              }
            );
          if (!inputSalesJobTrafficType.value.options.length) {
            ApiService.query(`/api/forwarder/job/shipped_via`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputSalesJobTrafficType.value.list =
                data.forwarder_job_shipped_vias;
              inputSalesJobTrafficType.value.options =
                data.forwarder_job_shipped_vias;
            });
          }
        }, 200);
      } else {
        inputSalesJobTrafficType.value.options = [];
      }
    };

    watch(
      () => props.forwarder_job_shipped_vias_id,
      (first) => {
        element_id.value = first;
      }
    );

    setTimeout(() => {
      getSalesJobTrafficType();
    });

    return {
      element_id,
      inputSalesJobTrafficType,
      errorMessage,
      meta,
      getSalesJobTrafficType,
      selectSalesJobTrafficType,
    };
  },
};
